export default [
  {
    code: "1616",
    format: "C6",
    color: "envelopesProperties.color.brown",
    type: "envelopesProperties.type.envelope",
    sealing: "envelopesProperties.sealing.gummed",
    gsm: 80,
    boxSize: 800,
  },
  {
    code: "3616",
    format: "C5",
    color: "envelopesProperties.color.brown",
    type: "envelopesProperties.type.envelope",
    sealing: "envelopesProperties.sealing.gummed",
    gsm: 80,
    boxSize: 400,
  },
  {
    code: "4604",
    format: "C4",
    color: "envelopesProperties.color.brown",
    type: "envelopesProperties.type.envelope",
    sealing: "envelopesProperties.sealing.gummed",
    gsm: 100,
    boxSize: 200,
  },
  {
    code: "5604",
    format: "B4",
    color: "envelopesProperties.color.brown",
    type: "envelopesProperties.type.envelope",
    sealing: "envelopesProperties.sealing.gummed",
    gsm: 100,
    boxSize: 200,
  },
]
